import { EFileSort, ICardQuery, EOrder, EOrderBy, EToDoTriggerText, todoTriggers, ECardType, ICombinedCard, EQuestionStatus, ETimePeriod, ETimeStatus, EToDoStatus, EVaccinationStatus } from "@types";
import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import medicationFDA from "@assets/json/medications.json";
import { isPast, isBeforeToday } from "./date";
import { DefaultOptionType } from "antd/es/cascader";

export const getQuery = (sort: EFileSort, type = "") => {
  let query: ICardQuery = {
    size: 1000,
    types: type,
    order: EOrder.DESC,
    orderBy: EOrderBy.CREATED_TIME,
  };
  switch (sort) {
    case EFileSort.New:
      query.order = EOrder.DESC;
      break;
    case EFileSort.Old:
      query.order = EOrder.ASC;
      break;
    case EFileSort.A:
      query.order = EOrder.ASC;
      query.orderBy = EOrderBy.TITLE;
      break;
    case EFileSort.Z:
      query.orderBy = EOrderBy.TITLE;
      break;
    case EFileSort.Recent:
      query.orderBy = EOrderBy.UPDATED_TIME;
      break;
    default:
      break;
  }
  return query;
};

export const getPropsCompleteToDo = (isCompleted: boolean, isMissed: boolean) => {
  if (!isCompleted && !isMissed) {
    return { icon: null, title: "Mark as Complete", missed: false };
  }
  const completed = isCompleted || !isMissed;
  return {
    icon: completed ? <CheckOutlined /> : <CloseCircleOutlined />,
    title: completed ? "Completed" : "Missed",
    missed: isMissed,
  };
};

export const getCriteriaCardType = (trigger: EToDoTriggerText) => {
  const triggerItem = todoTriggers.find(item => item.text === trigger);
  return triggerItem ? triggerItem.cardType : null;
};

export const isOverDueUpcoming = (card: ICombinedCard): boolean => {
  return (
    (card.cardType === ECardType.ToDos && isPast(card.startTime)) ||
    (card.cardType === ECardType.Vaccinations && isBeforeToday(card.endTime))
  );
};

export const splitCardsOverDue = (cards: ICombinedCard[]): [ICombinedCard[], ICombinedCard[]] => {
  const overDue: ICombinedCard[] = [];
  const notOverDue: ICombinedCard[] = [];

  cards.forEach((card) => {
    if (isOverDueUpcoming(card)) {
      overDue.push(card);
    } else {
      notOverDue.push(card);
    }
  });

  return [overDue, notOverDue];
};

export const ignoreFields = ["cardType", "title"];

export const searchMatchFields: {
  [key in string]: {
    [field: string]: string;
  }
} = {
  [ECardType.Allergies]: {
    type: 'Type',
    practitionerName: 'Practitioner',
    description: 'Notes',
    fileGroupName: 'Files',
  },
  [ECardType.Appointments]: {
    practitionerName: 'Practitioner',
    location: 'Location',
    description: 'Notes',
    fileGroupName: 'Files',
  },
  [ECardType.Conditions]: {
    type: 'Type',
    practitionerName: 'Practitioner',
    description: 'Notes',
    fileGroupName: 'Files',
  },
  [ECardType.Hospitalizations]: {
    type: 'Type',
    practitionerName: 'Practitioner',
    nurse: 'Nurse(s)',
    condition: 'Condition',
    description: 'Notes',
    fileGroupName: 'Files',
  },
  [ECardType.IdCard]: {
    description: 'Notes',
    fileGroupName: 'Files',
  },
  [ECardType.Medications]: {
    instructions: 'Instructions',
    reason: 'Reason',
    type: 'Type',
    description: 'Notes',
    'prescription.prescriber': 'Prescription Prescriber',
    'prescription.location': 'Prescription Location',
    fileGroupName: 'Files',
  },
  [ECardType.Notes]: {
    note: 'Note',
    fileGroupName: 'Files',
  },
  [ECardType.Practitioners]: {
    nurse: 'Nurse(s)',
    location: 'Location',
    description: 'Notes',
    fileGroupName: 'Files',
    patientPortal: 'Patient Portal',
  },
  [ECardType.Procedures]: {
    type: 'Type',
    nurse: 'Nurse(s)',
    condition: 'Condition',
    description: 'Notes',
    location: 'Location',
    conditionName: 'Condition',
    practitionerName: 'Practitioner',
    fileGroupName: 'Files',
  },
  [ECardType.Questions]: {
    backgroundInfo: 'Background Info',
    status: 'Status',
    practitionerName: 'Practitioner',
    fileGroupName: 'Files',
    answer: 'Answer',
    appointmentName: 'Appointment',
  },
  [ECardType.Vaccinations]: {
    description: 'Notes',
    practitionerName: 'Practitioner',
    location: 'Location',
    fileGroupName: 'Files',
  },
  [ECardType.Files]: {},
  [ECardType.ToDos]: {
    description: 'Details',
  },
};

export const getLabelOfField = (cardType: string, field: string): string => {
  const card = searchMatchFields[cardType];
  return card ? card[field] || '' : '';
};

interface IQueryListInfoTypes {
  activeQuery?: ICardQuery;
  inactiveQuery?: ICardQuery;
}

export const getQueryListInfoTypes = (
  cardType: ECardType
): IQueryListInfoTypes => {
  const queryCardType: { [key in ECardType]?: IQueryListInfoTypes } = {
    [ECardType.Procedures]: {
      activeQuery: { timeStatus: ETimeStatus.UPCOMING },
      inactiveQuery: { timeStatus: ETimeStatus.PAST },
    },
    [ECardType.Allergies]: {
      activeQuery: { timePeriod: ETimePeriod.PRESENT },
      inactiveQuery: { timePeriod: ETimePeriod.PAST },
    },
    [ECardType.Conditions]: {
      activeQuery: { timePeriod: ETimePeriod.PRESENT },
      inactiveQuery: { timePeriod: ETimePeriod.PAST },
    },
    [ECardType.Medications]: {
      activeQuery: { timePeriod: ETimePeriod.PRESENT },
      inactiveQuery: { timePeriod: ETimePeriod.PAST },
    },
    [ECardType.Appointments]: {
      activeQuery: { timeStatus: ETimeStatus.UPCOMING },
      inactiveQuery: { timeStatus: ETimeStatus.PAST },
    },
    [ECardType.Hospitalizations]: {},
    [ECardType.Vaccinations]: {
      activeQuery: { vaccinationStatus: EVaccinationStatus.VALID },
      inactiveQuery: { vaccinationStatus: EVaccinationStatus.OUT_OF_DATE },
    },
    [ECardType.Questions]: {
      activeQuery: { questionStatus: EQuestionStatus.UNANSWERED },
      inactiveQuery: { questionStatus: EQuestionStatus.OTHER },
    },
    [ECardType.IdCard]: {
      activeQuery: { timePeriod: ETimePeriod.PRESENT },
      inactiveQuery: { timePeriod: ETimePeriod.PAST },
    },
    [ECardType.Practitioners]: {
      activeQuery: { timePeriod: ETimePeriod.PRESENT },
      inactiveQuery: { timePeriod: ETimePeriod.PAST },
    },
    [ECardType.Notes]: {
      activeQuery: { order: EOrder.DESC, orderBy: EOrderBy.CREATED_TIME },
    },
    [ECardType.Files]: {},
    [ECardType.ToDos]: {
      activeQuery: { toDoStatus: EToDoStatus.ACTIVE },
      inactiveQuery: { toDoStatus: EToDoStatus.COMPLETED },
    },
  };
  return queryCardType[cardType] || {};
};

export const searchMedicationFDA = (value = ''): DefaultOptionType[] => {
  if (value?.length < 2) return [];
  const filterMedications = medicationFDA.medication
    .filter(({ id, title = '' }) =>
      title.toLowerCase().includes(value?.toLowerCase())
    )
    .map(({ id = '', title = '' }) => ({
      value: id,
      label: title,
    }));
  return filterMedications;
} 
