import { Flex } from "antd";

import { KFormUser } from "@components";
import { ECardType } from "@types";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const VaccinationDetails = () => {
  return (
    <Flex gap={16} vertical>
      <Flex gap={16} justify="space-between">
        <KFormUser.DatePicker label="Date Received" name="startTime" />
        <KFormUser.EndDatePicker label="Expiration Date" name="endTime" />
      </Flex>
      <KFormUser.Switch name="isNoLongerRemindMe" label="No longer remind me" />
      <KFormUser.OptionsSelector
        label="Practitioner"
        cardType={ECardType.Practitioners}
      />
      <KFormUser.TextArea
        label="Location"
        name="location"
        placeholder="Enter location info"
      />
      <KFormUser.OptionsSelector
        label="Appointment"
        cardType={ECardType.Appointments}
      />
      <KFormUser.TextArea name="description" label="Additional Information" />
      <AttachmentsDetail />
      <SetReminderSection />
    </Flex>
  );
};
