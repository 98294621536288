import { Divider } from "antd";

import { DividerLeftText, KSemiText700, Loading } from "@components";
import { useFetchInfoTypesService } from "@hooks";
import { useListInfoType } from "@store";
import { EFileSort, ICardAppointmentRes } from "@types";
import { isBeforeToday, isToday, isTomorrow, isUpcoming } from "@utils/date";
import { useMemo, useState } from "react";
import { AppointmentItem, SortItem } from "../item";
import { CustomSpaceList, WrapperCardList } from "../share-component";
import { getQuery } from "@utils/card";

export const ListAppointments = () => {
  const {
    pastAppointmentList = [],
    upcomingAppointmentList = [],
    isLoadingList,
    isLoadingInactiveList
  } = useListInfoType();
  const [sort, setSort] = useState<EFileSort>(EFileSort.New);

  const query = useMemo(() => getQuery(sort), [sort]);

  useFetchInfoTypesService("Appointments", query);

  const renderCardItems = (card: ICardAppointmentRes[] = []) => {
    return card.map((card: ICardAppointmentRes) => (
      <AppointmentItem key={card._id} card={card} />
    ));
  };

  const renderAppointmentsByDate = (
    dateCondition: (item: ICardAppointmentRes) => boolean,
    label: string,
    appointments: ICardAppointmentRes[]
  ) => {
    const filteredAppointments = appointments.filter(dateCondition);

    return filteredAppointments.length > 0 ? (
      <CustomSpaceList space={16}>
        {label && <DividerLeftText>{label}</DividerLeftText>}
        {renderCardItems(filteredAppointments)}
      </CustomSpaceList>
    ) : null;
  };

  return (
    <WrapperCardList space={16}>
      <CustomSpaceList>
        <KSemiText700>Upcoming Appointments</KSemiText700>
        <SortItem sort={sort} setSort={setSort} />
        {isLoadingList ? (
          <Loading />
        ) : (
          <CustomSpaceList space={24}>
            {renderAppointmentsByDate(
              (item) => isBeforeToday(item.startTime),
              "",
              upcomingAppointmentList
            )}
            {renderAppointmentsByDate(
              (item) => isToday(item.startTime) || !item.startTime,
              "TODAY",
              upcomingAppointmentList
            )}
            {renderAppointmentsByDate(
              (item) => isTomorrow(item.startTime),
              "TOMORROW",
              upcomingAppointmentList
            )}
            {renderAppointmentsByDate(
              (item) => isUpcoming(item.startTime),
              "UPCOMING",
              upcomingAppointmentList
            )}
          </CustomSpaceList>
        )}
      </CustomSpaceList>
      <Divider />
      {pastAppointmentList.length > 0 && (
        <CustomSpaceList>
          <KSemiText700>Past Appointments</KSemiText700>
          {isLoadingInactiveList ? <Loading /> : (
            renderCardItems(pastAppointmentList)
          )}
        </CustomSpaceList>
      )}
    </WrapperCardList>
  );
};
