import { handleMapOptions } from "@utils/function-helper";
import { IKSelectOption } from "./global";

export enum ESex {
  MALE = "Male",
  FEMALE = "Female",
}

export enum EPronouns {
  HE = "He/him",
  SHE = "She/her",
  THEY = "They/them",
}

export enum BloodType {
  A_POSITIVE = "A Positive",
  A_NEGATIVE = "A Negative",
  B_POSITIVE = "B Positive",
  B_NEGATIVE = "B Negative",
  AB_POSITIVE = "AB Positive",
  AB_NEGATIVE = "AB Negative",
  O_POSITIVE = "O Positive",
  O_NEGATIVE = "O Negative",
}

export enum EHeightUnit {
  FT = "ft",
  IN = "in",
  CM = "cm",
}

export enum EWeightUnit {
  LBS = "lbs",
  KG = "kg",
}

export const yesOrNoOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const pronouns: IKSelectOption[] = handleMapOptions(EPronouns);

export const sexes: IKSelectOption[] = handleMapOptions(ESex);

export const bloodTypes: IKSelectOption[] = handleMapOptions(BloodType);

export const weightTypes: IKSelectOption[] = handleMapOptions(EWeightUnit);

export const heightTypes: IKSelectOption[] = handleMapOptions(EHeightUnit);

export enum AllergyTypes {
  FOOD = "Food",
  MEDICATION = "Medication",
  OTHER = "Other",
}

export enum AllergySeverity {
  MILD = "Mild",
  MODERATE = "Moderate",
  SEVERE = "Severe",
}

export enum AllergyType {
  Food = "Food",
  Medication = "Medication",
  Other = "Other",
}

export const AllergiesEntityName = "Allergies";
export const NotesEntityName = "Notes";
export const FilesEntityName = "Files";

export enum EEntityName {
  Profiles = "Profiles",
  Allergies = "Allergies",
  Notes = "Notes",
  Files = "Files",
  Users = "Users",
  ProfileAcl = "ProfileAcl",
  Relationship = "Relationship",
  BasicInfo = "BasicInfo",
  HealthDetail = "HealthDetail",
  EmergencyContact = "EmergencyContact",
  SecurityInformation = "SecurityInformation",
  UserName = "UserName",
  SharedUser = "SharedUser",
  TopicCard = "TopicCard",
  Questions = "Questions",
  Medications = "Medications",
  FillHistories = "FillHistories",
  Prescriptions = "Prescriptions",
  Appointments = "Appointments",
  Conditions = "Conditions",
  Hospitalizations = "Hospitalizations",
  Procedures = "Procedures",
  Reminders = "Reminders",
  Vaccinations = "Vaccinations",
  Practitioners = "Practitioners",
  IdCards = "IdCards",
  Topic = "Topic",
  Storage = "Storage",
}

export const PhoneNumberUSStateList = [
  228, 601, 662, 769, 670, 405, 539, 580, 918, 302, 218, 320, 507, 612, 651,
  763, 952, 217, 224, 309, 312, 331, 618, 630, 708, 773, 779, 815, 847, 872,
  479, 501, 870, 505, 575, 219, 260, 317, 574, 765, 812, 240, 301, 410, 443,
  667, 225, 318, 337, 504, 985, 208, 307, 423, 615, 731, 865, 901, 931, 480,
  520, 602, 623, 928, 319, 515, 563, 641, 712, 231, 248, 269, 313, 517, 586,
  616, 734, 810, 906, 947, 989, 316, 620, 785, 913, 385, 435, 801, 684, 458,
  503, 541, 971, 203, 475, 860, 406, 209, 213, 310, 323, 408, 415, 424, 442,
  510, 530, 559, 562, 619, 626, 650, 657, 661, 669, 707, 714, 747, 760, 805,
  818, 831, 858, 909, 916, 925, 949, 951, 339, 351, 413, 508, 617, 774, 781,
  857, 978, 787, 939, 803, 843, 864, 603, 262, 414, 534, 608, 715, 920, 802,
  229, 404, 470, 478, 678, 706, 762, 770, 912, 701, 215, 267, 272, 412, 484,
  570, 610, 717, 724, 814, 878, 304, 681, 239, 305, 321, 352, 386, 407, 561,
  727, 754, 772, 786, 813, 850, 863, 904, 941, 954, 808, 270, 502, 606, 859,
  907, 308, 402, 531, 314, 417, 573, 636, 660, 816, 216, 234, 330, 419, 440,
  513, 567, 614, 740, 937, 205, 251, 256, 334, 938, 401, 202, 340, 605, 303,
  719, 720, 970, 201, 551, 609, 732, 848, 856, 862, 908, 973, 276, 434, 540,
  571, 703, 757, 804, 671, 206, 253, 360, 425, 509, 252, 336, 704, 828, 910,
  919, 980, 984, 212, 315, 347, 516, 518, 585, 607, 631, 646, 716, 718, 845,
  914, 917, 929, 210, 214, 254, 281, 325, 346, 361, 409, 430, 432, 469, 512,
  682, 713, 737, 806, 817, 830, 832, 903, 915, 936, 940, 956, 972, 979, 702,
  725, 775, 207,
];

export enum EErrorMessage {
  REQUIRED = "This field is required.",
  SSN = "Please enter a valid SSN.",
  PHONE = "Please enter a valid phone number.",
  FAX = "Please enter a valid fax number.",
  LINK = "Please enter a valid link.",
}

export enum Method {
  POST = "POST",
  PUT = "PUT",
}

export enum EDateTimeFormat {
  FULL_MONTH_YEAR = "MMMM D, YYYY",
  FULL_MONTH_YEAR_WITH_HOUR = "MMMM D, YYYY [at] h a",
  FULL_MONTH_YEAR_WITH_TIME_AM_PM = "MMMM D, YYYY [at] h:mm a",
  TIME_AM_PM = "hh:mm A",
  TIME_AM_PM_NO_SPACE = "hh:mma",
  DATE = "YYYY-MM-DD",
  FULL_TIME = "HH:mm:ss.SSS",
  UTC = "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  FULL_DAY_MONTH_YEAR_WITH_SHORT_MONTH = "MMM[.] D[,] YYYY",
  FULL_DAY_MONTH_YEAR_WITH_SHORT_MONTH_AND_TIME_AM_PM = "MMM[.] D[,] YYYY [at] h:mma",
  FULL_DAY_MONTH = "DD/MM",
  FULL_MONTH_DAY = "MM/DD",
  MONTH_DAY_WITH_SHORT_YEAR_AND_TIME_AM_PM = "MM/DD [at] h:mma",
  FULL_DAY_MONTH_SHORT_YEAR_AND_TIME_AM_PM = "MM/DD/YY [at] h:mma",
  TIME_AM_PM_NO_SEPARATE = "hh.mma",
  SHORT_DAY_WITH_DOT = "ddd.",
  FULL_DAY_SHORT_MONTH_YEAR_WITH_DOT = "dddd MMM. D",
  DAY_MONTH_YEAR_WITH_SPLASH = "DD/MM/YYYY",
  MONTH_DAY_YEAR_WITH_SPLASH = "MM/DD/YYYY",
  MONTH_YEAR = "MMMM YYYY",
  FULL_YEAR_MONTH_DAY_WITH_SHORT_MONTH_AND_TIME_AM_PM = "YYYY-MM-DD, h:mmA",
}

export enum ESkeleton {
  SECTION_GAP = 8,
  CARD_PADDING = 12,
  SECTION_PADDING = 16,
  TOPIC_CARD_PADDING = 8,
  CARD_GAP = 4,
  CARD_RADIUS = 8,
  DETAIL_GAP = 20,
  DETAIL_PADDING = 16,
  RADIUS = 8,
  ROW_GAP = 16,
  FORM_GROUP_GAP = 8,
  TEXT_AREA_HEIGHT = 64,
  TEXT_EDITOR_HEIGHT = 250,
  INPUT_HEIGHT = 32,
  LABEL_HEIGHT = 18,
  ATTACHMENT_HEIGHT = 150,
}

export enum EOrderByParams {
  START_TIME = "startTime",
  TITLE = "title",
  UPDATED_TIME = "updatedTime",
}

export enum EOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum EOrderBy {
  TITLE = "title",
  UPDATED_TIME = "updatedTime",
  CREATED_TIME = "createdTime",
  START_TIME = "startTime",
}

export enum EOrderFile {
  ASC_TIME = "asc_time",
  DESC_TIME = "desc_time",
  ASC_TITLE = "asc_title",
  DESC_TITLE = "desc_title",
}

export enum EFileType {
  ALL = "All",
  IMAGES = "Images",
  DOCUMENTS = "Documents",
  OTHER = "Other",
}

export enum ELineHeight {
  sm = 16,
  md = 18,
  lg = 24,
  semi = 28,
  xl = 28,
  xxl = 32,
  xxxl = 38,
  xxxxl = 60,
}

export enum EHeightInput {
  sm = 24,
  md = 32,
  semi = 40,
  lg = 40,
  xl = 50,
  xxl = 60,
  xxxl = 72,
  xxxxl = 86,
}

export enum EFontSize {
  sm = 13,
  md = 15,
  lg = 17,
  semi = 20,
  xl = 24,
  xxl = 28,
  xxxl = 30,
  xxxxl = 44,
}

export enum ESexBirth {
  MALE = "Male",
  FEMALE = "Female",
}

export enum EFileSort {
  New = "New",
  Old = "Old",
  A = "A",
  Z = "Z",
  Recent = "Recent",
}

export enum EMedicationFrequency {
  EVERY_4_HOURS = "Every 4 hours",
  EVERY_6_HOURS = "Every 6 hours",
  EVERY_8_HOURS = "Every 8 hours",
  EVERY_12_HOURS = "Every 12 hours",
  DAILY_MORNING = "Daily – Morning",
  DAILY_AFTERNOON = "Daily – Afternoon",
  DAILY_EVENING = "Daily – Evening",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly",
  AS_NEEDED = "As Needed",
}

export enum EStatusCode {
  FORBIDDEN = 403,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  INFO = "info",
}

export enum EToDoStatus {
  COMPLETED = "completed",
  ACTIVE = "active",
}

export enum EStatusSuccess {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
}

export enum ELocalStorageKey {
  TempShare = "tempShareToken",
  WebApp = "webAppToken",
  WebAppRefresh = "webAppRefreshToken",
  Export = "exportToken",
  FCM = "FCMToken",
  Pin = "last_enter_pin_success",
  EmergencyAccess = "emergencyAccessToken",
  Profile = "profile_id",
  Username = "username"
}
