import { VectorRightIcon } from "@assets/icons/VectorRightIcon";
import { EFontSize, ESpacing } from "@types";
import { FormItem } from "react-hook-form-antd";
import styled from "styled-components";

export const FormItemStyled = styled(FormItem)`
  &.ant-form-item {
    margin: 0;
    flex: 1;
    .ant-row {
      gap: 8px;
    }
  }
  .ant-form-item-label {
    padding: 0;
    label {
      height: fit-content!important;
    }
  }
`;

export const FormItemBorderless = styled(FormItem)`
  &.ant-form-item {
    margin: 0;
    flex: 1;
    .ant-row {
      align-items: center;
    }
    .ant-form-item-label {
      display: flex;
      & > label {
        padding: 2px 0;
      }
      & > label::after {
        margin-inline-start: 0;
        margin-inline-end: 0;
      }
      svg {
        font-size: 24px;
      }
    }
  }
  @media (max-width: 575px) {
    .ant-form-item-label {
      padding: 0;
      flex: none !important;
    }
    &.ant-form-item .ant-row {
      flex-wrap: nowrap;
    }
  }
`;

export const FormItemSwitch = styled(FormItem)`
  &.ant-form-item {
    margin: 0;
    flex: 1;
    .ant-row {
      justify-content: space-between;
    }
  }
  .ant-form-item-control-input-content {
    max-width: fit-content;
  }
  .ant-form-item-control {
    max-width: fit-content !important;
    align-items: center;
  }
  .ant-form-item-control-input {
    height: fit-content;
    min-height: auto;
  }
  &.ant-form-item .ant-form-item-label > label {
    height: fit-content;
    font-size: ${EFontSize.md}px;
  }
  @media (max-width: 575px) {
    .ant-form-item-label {
      padding: 0;
      flex: none !important;
    }
  }
`;

export const IconStyled = styled(VectorRightIcon)`
  width: 20px;
  height: 20px;
  margin-left: ${ESpacing.md};
`;
